// Font Awesome
$fa-font-path: "./fonts" !default;

// Slick
$slick-font-path: "/fonts/";
$slick-loader-path: "/images/";

// Bootstrap
$grid-columns: 12;
$grid-gutter-width: 60px !default;
$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 768px,
    lg: 1200px,
    xlg: 1600px
);
$container-max-widths: (
    sm: 735px,
    md: 735px,
    lg: 1140px,
    xlg: 1365px
);

// Hamburger
// Available options here: https://jonsuh.com/hamburgers/#sass
$hamburger-hover-opacity: 1;
$hamburger-layer-color: #fff;