.testimonial-block {
  padding: 60px 0 80px;
  img {
    width: 80px;
    padding-bottom: 20px;
  }

  .h5 {
    color: $shakespeare;
  }
}