$black: #000000;
$pale-sky: #6B7581;
$nile-blue: #1A3050;
$st-tropaz: #2A528E;
$shakespeare: #3C97D3;
$ghost: #BFC4CE;
$pattens-blue: #D3EDFF;
$white-smoke: #F7F7F7;
$whisper: #F7F8FB;
$white: #FFFFFF;
$light-gray: #CDCDCD;