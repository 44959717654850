.error-page {
  .centered-hero-block {
    > svg {
      position: absolute;
      top: 0;
      left: 50%;
      width: calc(100% - 40px);
      max-width: 500px;
      transform: translateX(-50%);

      @include media-breakpoint-up(md) {
        top: 130px;
        max-width: 650px;
      }

      @include media-breakpoint-up(lg) {
        top: 180px;
        max-width: 900px;
      }
    }

    .container {
      padding-top: 40px;

      @include media-breakpoint-up(md) {
        padding-bottom: 120px;
      }
      @include media-breakpoint-up(xlg) {
        padding-bottom: 150px;
      }

      .btn-secondary {
        background: $white;

        &:hover {
          background: $nile-blue;
        }
      }
    }
  }
}