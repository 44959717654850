.accordion {
  .card-spacer {
    margin: 20px 0;
    border-top: 1px solid $ghost;

    &:last-of-type {
      border-bottom: 1px solid $ghost;
    }
  }
  .card {
    background-color: transparent;
    border: none;
    border-left: 3px solid transparent;
    border-radius: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    transition: border-left-color 0.25s linear;

    &.open {
      border-left-color: $shakespeare;
    }

    .card-header {
      background-color: transparent;
      border-bottom: none;
      padding: 0 30px;

      button.btn.btn-link {
        margin-top: 0;
        margin-bottom: 0;
        min-width: 0;
        height: auto;
        padding: 0;
        text-align: left;
        box-shadow: none;
        border: none;
      }
    }
    .card-body {
      background-color: transparent;
      padding: 20px 30px 0;

      p {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}