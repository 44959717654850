form {
  position: relative;

  .form-group {
    margin-bottom: 40px;
  }

  label {
    color: $st-tropaz;
    font-family: $exo;
    font-size: 21px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 10px;
  }

  small, .form-text {
    color: $pale-sky;
    font-family: $roboto;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
    margin-top: 0;
  }

  input, textarea, .form-control {
    border-radius: 5px 5px 5px 5px;
    border: 1px solid $ghost;
    box-shadow: 0px 0px 6px rgba(124, 124, 124, .72);
    line-height: 25px;
    vertical-align: top;
    padding: 14.3px 23px;
    font-family: $exo;
    font-size: 16px;
    color: $pale-sky;
    outline: none;
    height: auto;
    margin-top: 10px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }

    &:focus, &:valid {
      border-color: $shakespeare;
      outline: none;
      box-shadow: 0px 0px 6px rgba(124, 124, 124, .72);
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-family: $exo;
      font-size: 16px;
      line-height: 25px;
      color: $pale-sky;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }
    &::-moz-placeholder { /* Firefox 19+ */
      font-family: $exo;
      font-size: 16px;
      line-height: 25px;
      color: $pale-sky;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }
    &:-ms-input-placeholder { /* IE 10+ */
      font-family: $exo;
      font-size: 16px;
      line-height: 25px;
      color: $pale-sky;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }
    &:-moz-placeholder { /* Firefox 18- */
      font-family: $exo;
      font-size: 16px;
      line-height: 25px;
      color: $pale-sky;

      @include media-breakpoint-up(md) {
        font-size: 18px;
      }
    }
  }

  .form-portability {
    button.btn-primary {
      margin-top: 0;
    }

  }

  &.form-subscribe {
    .newsletter {
      border-radius: 5px;
      box-shadow: 0px 3px 15px rgba(124, 124, 124, .72);
      position: relative;
      z-index: 2;

      input {
        border-radius: 5px 0 0 5px;
        border: 1px solid transparent;
        height: 45px;
        //line-height: 45px;
        //vertical-align: top;
        margin-top: 0;
        width: calc(100% - 83px);
        //padding: 0 23px;
        //font-family: $exo;
        //font-size: 16px;
        //color: $pale-sky;
        //outline: none;
        box-shadow: none;

        @include media-breakpoint-up(md) {
          height: 55px;
          //font-size: 18px;
          width: calc(100% - 177px);
        }

        &:valid ~ button.subscribe {
          border-color: $st-tropaz;
          background-color: $st-tropaz;
        }
      }
      button.subscribe {
        border-radius: 0 5px 5px 0;
        min-width: 0;
        width: 83px;
        box-shadow: none;
        position: absolute;
        margin-top: 0;
        right: 0;

        @include media-breakpoint-up(md) {
          width: 177px;
        }
      }
    }
  }
}

.newsletter-signup-block {
  form.form-subscribe {
    .success-message {
      opacity: 0;
      position: absolute;
      z-index: 1;
      transition: opacity .25s linear;
      left: 50%;
      transform: translateX(-50%);
      top: 75px;
      color: $st-tropaz;
      font-weight: 600;
      width: 100%;
      max-width: 550px;
      text-align: center;

      &.active {
        opacity: 1;
      }
    }
  }
}

footer {
  form.form-subscribe {
    .success-message {
      opacity: 0;
      position: absolute;
      z-index: 1;
      transition: opacity .25s linear;
      color: $white;
      font-size: 15px;
      top: 65px;
      text-align: center;

      @include media-breakpoint-up(md) {
       text-align: left;
        top: 75px;
      }

      &.active {
        opacity: 1;
      }
    }
  }
}