.search-page {
  .results {
    a {
      text-decoration: none;
      margin: 20px 0;

      .card {
        border-radius: 5px;
        border: none;
        box-shadow: 0px 3px 20px rgba(124, 124, 124, .72);
        padding: 60px 30px;

        p {
          b {
            color: $shakespeare;
          }
        }

        @include media-breakpoint-up(lg) {
          padding: 80px 110px;
        }

        .entry-url {
          font-size: 14px;
          line-height: 14px;
          font-family: $roboto;
        }
      }
    }
  }
}