footer {
  background: transparent linear-gradient(199deg, #2A528E 0%, #1A3050 100%) 0% 0% no-repeat padding-box;

  .container {
    margin: 0;
    padding: 0;
    max-width: unset;
  }

  .footer-wrapper {
    padding: 60px 30px 0;

    @include media-breakpoint-up(md) {
      padding: 98px 60px 111px;
      max-width: 990px;
    }

    @include media-breakpoint-up(lg) {
      padding: 98px 60px 60px;
    }

    .footer-links {

      &:first-of-type {
        max-width: 240px;
      }

      p {
        font-family: $exo;
        color: $white;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
          font-size: 24px;
        }
      }
      .link-list-wrapper {
        border-left: 1px solid #3C97D3;
        margin: 10px 0 60px;
        padding: 7px 0 0 20px;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }

        ul {
          list-style: none;
          padding: 0;

          li {
            font-family: $exo;
            color: $white-smoke;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 22px;

            @include media-breakpoint-up(lg) {
              font-size: 24px;
              margin-bottom: 36px;
            }

            &:last-of-type {
              margin-bottom: 0;
            }

            a, span {
              color: $white-smoke;
              font-size: 18px;
              line-height: 18px;
              text-decoration: none;
              font-family: $exo;

              &.email {
                text-decoration: underline;
              }

              @include media-breakpoint-up(lg) {
                font-size: 24px;
              }
            }
          }
        }

        .social-links {
          a {
            img {
              height: 40px;
            }
          }
        }
      }
    }
  }

  .footer-lower-wrapper {
    position: relative;

    @include media-breakpoint-up(md) {
      margin-bottom: 60px;
      padding: 0 40px 0 60px;
    }

    .newsletter-signup {
      //margin-bottom: 40px;
      margin-bottom: 90px;
      width: 100%;
      max-width: 471px;

      @include media-breakpoint-up(md) {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: bottom;
      }

      @include media-breakpoint-up(xlg) {
        position: absolute;
        right: 74px;
        top: -343px;
      }

      .newsletter-title {
        color: $white;
        font-size: 21px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 25px;
        font-family: $exo;

        @include media-breakpoint-up(md) {
          font-size: 24px;
          line-height: 32px;
        }

        form.form-subscribe {
          .newsletter {
            width: 100%;

            input {
              width: calc(100% - 83px);

              @include media-breakpoint-up(md) {
                width: 83px;
              }
            }
          }
        }
      }
    }

    .footer-logo {
      text-align: center;
      margin-bottom: 56px;

      @include media-breakpoint-up(md) {
        display: inline-block;
        margin-bottom: 0;
        position: absolute;
        bottom: 0;
        right: 40px;
      }

      @include media-breakpoint-up(xlg) {
        right: 60px;
      }

      img{
        height: 100px;
        width: auto;

        @include media-breakpoint-up(md) {
          height: 140px;
        }
      }
    }
  }
  .footer-bottom {
    text-align: center;
    background-color: $nile-blue;
    padding-bottom: 20px;
    padding-top: 16px;

    @include media-breakpoint-up(md) {
      text-align: initial;
      padding-bottom: 14px;
      height: 46px;
      padding-top: 0;
    }

    .copyright {
      position: relative;
      padding-bottom: 17px;

      @include media-breakpoint-up(md) {
        display: inline-block;
        top: 12px;
        padding-bottom: 0;
      }

      p {
        color: $pattens-blue;
        font-size: 14px;
        line-height: 14px;
        font-family: $roboto;
        font-weight: 700;

        @include media-breakpoint-up(lg) {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }

    .legal {
      position: relative;

      @include media-breakpoint-up(md) {
        display: inline-block;
        top: 11px;
        float: right;
      }

      a {
        border-right: 2px solid $pattens-blue;
        color: $pattens-blue;
        font-size: 14px;
        line-height: 14px;
        font-family: $roboto;
        font-weight: 700;
        position: relative;
        text-transform: uppercase;
        text-decoration: none;

        @include media-breakpoint-up(lg) {
          font-size: 16px;
          line-height: 16px;
        }

        &:first-of-type {
          padding-right: 20px;
        }

        &:last-of-type {
          border-right: none;
          padding-left: 17px;
        }

        span {
          &.full{
            display: none;

            @include media-breakpoint-up(lg) {
              display: inline-block;

            }
          }
          &.abbreviation {
            @include media-breakpoint-up(lg) {
              display: none;

            }
          }
        }
      }
    }
  }
}