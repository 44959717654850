.collapsible-feature-block {
  .d-lg-none {
    .image-content {
      height: 0;
      margin-bottom: 20px;
      position: relative;
      padding-top: calc((600/850) * 100%);

      @include media-breakpoint-up(md) {
        margin-bottom: 30px;
      }
      @include media-breakpoint-up(lg) {
        align-items: center;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-height: 100%;
        max-width: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .d-lg-flex {
    .flex-image {
      @include media-breakpoint-up(lg) {
        flex: 1;
      }

      .image-content {
        position: relative;
        height: 0;
        padding-top: calc((600/850) * 100%);

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          max-height: 100%;
          max-width: 100%;
          opacity: 0;
          transform: translate(-50%, -50%);
          transition: opacity .3s ease-in-out;

          &.active {
            opacity: 1;
          }
        }
      }
    }
    .flex-content {
      @include media-breakpoint-up(lg) {
        flex: 0 1 436px;
      }
    }
  }
}