.contact-details-block {
  .social-icon {
    display: inline-block;
    margin: 0 10px;
  }
  #map {
    height: 315px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      height: 400px;
    }
  }
}