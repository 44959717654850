.cta-block {
  &.container-fluid {
    padding-top: 95px;
    padding-bottom: 95px;
    position: relative;
  }

  .section-header {
    position: relative;
    z-index: 1;

    a {
      margin-top: 30px;
    }
  }
}