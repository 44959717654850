.feature-list-block {
  .item {
    margin-bottom: 15px;
    margin-top: 15px;
    max-width: 355px;

    .image-content {
      margin-right: 15px;
      width: 24px;

      img {
        width: 24px;
        height: auto;
      }
    }

    p {
      max-width: calc(100% - 39px);
    }
  }
}