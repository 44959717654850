@import url('https://fonts.googleapis.com/css?family=Exo:100,200,300,400,500,600,700,800,900|Roboto:Roboto:100,300,400,500,700,900');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

$exo: 'Exo', sans-serif;
//thin = font-weight: 100
//extra-light = font-weight: 200
//light = font-weight: 300
//regular = font-weight: 400
//medium = font-weight: 500
//semi-bold = font-weight: 600
//bold = font-weight: 700
//extra-bold = font-weight: 800
//black = font-weight: 900


$roboto: 'Roboto', sans-serif;
//thin = font-weight: 100
//light = font-weight: 300
//regular = font-weight: 400
//medium = font-weight: 500
//bold = font-weight: 700
//black = font-weight: 900