.feature-request-block {
  .card {
    border-radius: 5px;
    border: none;
    box-shadow: 0px 3px 20px rgba(124, 124, 124, .72);
    padding: 60px 15px;

    @include media-breakpoint-up(lg) {
      padding: 80px 110px;
    }
  }
}