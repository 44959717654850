.fifty-fifty-callout-block {
  .px-30 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .content {
    p, ul {
      margin-top: 30px;
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0;

        li:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}