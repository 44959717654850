.help-options-block {
  .option {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px 0;

    @include media-breakpoint-up(lg) {
      &:nth-of-type(3n - 1) {
        border-left: 1px solid $pale-sky;
        border-right: 1px solid $pale-sky;
      }
    }

    .h5 {
      color: $shakespeare;
    }
    .h8 {
      font-weight: 400;
    }

    .btn.btn-primary {
      margin-top: 30px;
    }
  }
}