.hardware-item-content-block {

  .fifty-fifty-callout-block {
    .content {
      .h6 {
        margin-bottom: 0;
      }
      .s3 {
        margin-top: 0;
      }
    }
  }

}