.hardware-grid-block {
  .container {
    padding-left: 10px;
    padding-right: 10px;
    .row {
      margin-right: -10px;
      margin-left: -10px;

      .col-lg-3 {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  .item {
    margin: 20px 0;
    .card {
      width: 315px;
      height: 315px;
      padding: 30px;
      box-shadow: 0 0 10px rgba(124, 124, 124, 0.29);
      border-radius: 5px;
      position: relative;

      @include media-breakpoint-up(lg) {
        width: 265px;
        height: 265px;
      }
      @include media-breakpoint-up(xlg) {
        width: 315px;
        height: 315px;
      }

      .hover-gradient {
        background: transparent linear-gradient(180deg, #2A528E 0%, #1A3050 100%) 0% 0% no-repeat padding-box;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        border-radius: 5px;
        pointer-events: none;
        transition: opacity 0.25s linear;
      }

      .hover-show {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.25s linear;
      }

      &:hover {
        .hover-gradient {
          opacity: .48;
        }
        .hover-show {
          opacity: 1;
        }
      }

      img {
        width: 100%;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .s5 {
      color: $st-tropaz;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
}