nav.navbar#topNav {
  height: 80px;
  padding: 0;
  background-color: $white;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    box-shadow: 0 3px 6px rgba(124, 124, 124, 0.29);
    z-index: 100;
    height: 80px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  a.navbar-brand {
    margin-left: 16px;
    img {
      width: 130px;
    }
  }

  .hamburger {
    width: 42px;
    padding: 5px;
    position: relative;
    top: 3px;
    margin-right: 16px;

    &:focus {
      outline: none;
    }

    .hamburger-box {
      width: 32px;

      &:focus {
        outline: none;
      }
    }
    span.hamburger-inner {
      display: block;
      //position: absolute;
      //top: (30px / 2) - ( 2px / 2);
      //height: 3px;
      width: 100%;
      background-color: $shakespeare;

      &::before,
      &::after {
        width: 100%;
        //position: absolute;
        display: block;
        //left: 0;
        //height: 3px;
        background-color: $shakespeare;
        content: "";
      }

    }
  }

  .top-right {
    position: absolute;
    top: 16px;
    right: 30px;
  }

  .navbar-collapse {
    background: $white;
    padding: 23px 30px 38px 30px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 80px;
    height: calc(100vh - 80px) !important;
    overflow: auto;

    .nav-link {
      &:after {
        display: none;
      }
      &.dropdown-toggle {
        font-size: 30px;
        line-height: 40px;
        color: $pale-sky;
        text-decoration: none;
        font-family: $exo;
      }
    }

    .dropdown-menu {
      border-top: 0;
      border: none;
      padding: 0;
      margin: 0;
    }

    .megamenu {
      position: static;

      .nav-indicator {
        display: none;
      }

      > .dropdown-menu {
        width: 100%;
        margin: 20px 0;

        li {
          margin: 20px 0;

          a {
            text-decoration: none;

            .card {
              border: 2px solid $white;
              box-shadow: 0 0 20px rgba(124, 124, 124, 0.29);
              border-radius: 5px;
              transition: broder-color .2s ease-in-out;

              .content {
                padding: 22px 18px;

                .h5 {
                  font-size: 21px;
                  line-height: 24px;
                  margin-bottom: 5px;
                }
                p {
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }

            &:hover {
              .card {
                border-color: $shakespeare;
              }
            }
          }
        }
      }
    }
  }

  .search-form {
    input {
      margin: 0;
      height: 100%;

      &[type="text"] {
        border-color: transparent;

        &:focus {
          border-color: $shakespeare;
        }
      }
    }
  }
  .login-form {
    input {
      max-width: 250px;
      margin-top: 16px;

      &[type="text"],
      &[type="password"] {
        border-color: transparent;

        &:focus {
          border-color: $shakespeare;
        }
      }

      &[type="submit"] {
        max-width: 177px;
      }
    }
    ~ .links {
      margin-top: 40px;

      a {
        margin-right: 40px;
        font-weight: bold;
      }
    }
  }
  .status-block {
    .contact-number {
      font-family: $exo;
      font-size: 16px;
      line-height: 21px;
      color: $pale-sky;

      &:after {
        content: '';
        border-right: 2px solid $pale-sky;
        width: 1px;
        height: 100%;
        margin-left: 15px;
        margin-right: 11px;
      }

      &:focus {
        outline: none;
      }
    }
    a {
      text-decoration: none;
    }
    .status {
      font-family: $exo;
      font-size: 16px;
      line-height: 21px;
      color: $pale-sky;

      [data-role="status-indicator"] {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background: transparent linear-gradient(138deg, #00EA63 0%, #19AA46 100%) 0% 0% no-repeat padding-box;
        position: relative;
        top: 2px;
        left: 5px;

        &.down {
          background: transparent linear-gradient(138deg, #ea0000 0%, #aa1919 100%) 0% 0% no-repeat padding-box;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .navbar-collapse {
      .nav-link {
        &.dropdown-toggle {
          font-size: 50px;
          line-height: 60px;
        }
      }
      .megamenu {
        > .dropdown-menu {
          li {
            width: calc(50% - 13px);
            display: inline-block;
            margin: 5px 10px 10px 0;

            a {
              .card {
                .content {
                  padding: 40px 30px;

                  h5 {
                    font-size: 24px;
                    line-height: 28px;
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    height: 155px;
    transition: height .2s ease-in-out;

    &:after {
      height: 155px;
      transition: height .2s ease-in-out;
    }

    a.navbar-brand {
      margin-left: 40px;

      img {
        width: 300px;
        transition: width .2s ease-in-out;
      }
    }

    .navbar-collapse {
      padding: 0;
      background: none;
      position: static;
      height: auto !important;
      height: initial !important;

      .nav.navbar-nav {
        justify-content: flex-end;
        width: 100%;
        margin-right: 40px;
        margin-top: 35px;
        transition: margin-top .2s ease-in-out;

        .nav-item.dropdown {
          margin-left: 45px;
        }
      }

      .megamenu {

        > .dropdown-menu {
          background: url('/images/NocTel-Squares_Light-Grey.svg') repeat-x top left, $white;
          margin: 0;
          padding: 0;
          box-shadow: 0 10px 10px rgba(124, 124, 124, 0.29);
          border-radius: 0;
          z-index: 1;
          opacity: 0;
          display: block;
          overflow: hidden;
          transition: opacity .3s ease-in-out;
          pointer-events: none;

          &:before {
            content: '';
            height: 10px;
            width: 100%;
            background: transparent linear-gradient(90deg, #D3EDFF 0%, #F7F8FB 100%) 0 0 no-repeat padding-box;
            top: 0;
            display: block;
          }

          &.show {
            opacity: 1;
            pointer-events: all;
          }

          .nav-indicator {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            transition: all .2s ease-in-out;
            background: $shakespeare;
            height: 10px;
          }

          &.dropdown-menu-small {
            width: 360px;
            left: auto;
            right: 0;

            .nav-indicator {
              display: none;
            }
          }

          .row {
            padding: 20px 50px;

            .h3 {
              font-size: 40px;
              line-height: 70px;
              color: $pale-sky;
              margin-top: 10px;
              margin-bottom: 10px;
            }

            li {
              width: calc(33% - 26px);
              display: inline-block;
              margin: 5px 40px 35px 0;

              &:nth-child(3n) {
                margin-right: 0;
              }
            }
          }
        }
      }

      .nav-link {
        &.dropdown-toggle {
          font-size: 20px;
          line-height: 26px;
          font-weight: 600;
          color: $st-tropaz;
        }
      }
    }
    .top-right {
      right: 50px;
      transition: top .2s ease-in-out;
    }
    .login-form {
      ~ .links {
        a {
          margin-right: 0;
          margin-left: 20px;
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
    .search-form {
      margin: 16px 0;
    }

    &.shrink {
      height: 95px;

      &:after {
        height: 95px;
      }

      a.navbar-brand {
        img {
          width: 160px;
        }
      }
      .top-right {
        top: -29px;
      }
      .navbar-collapse {
        .nav.navbar-nav {
          margin-top: 5px;
        }
      }
    }
  }

  @include media-breakpoint-up(xlg) {
    .navbar-collapse {
      .nav-link {
        &.dropdown-toggle {
          font-size: 26px;
          line-height: 35px;
        }
      }
      .megamenu {
        > .dropdown-menu {
          .row {
            padding: 20px 60px;

            li {
              width: calc(25% - 34px);
              &:nth-child(3n) {
                margin-right: 40px;
              }
              &:nth-child(4n) {
                margin-right: 0;
              }

              a {
                .card {
                  .content {
                    .h5 {
                      font-size: 24px;
                      line-height: 28px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}