.pricing-block {
  .item {
    max-width: 355px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    .image-content {
      margin-right: 15px;
    }
  }
  .pricing {
    justify-content: center;

    .card {
      border-radius: 5px;
      border: none;
      box-shadow: 0px 3px 15px rgba(124, 124, 124, .72);
      margin: 30px 10px 0;
      flex: 0 0 115px;

      @include media-breakpoint-up(lg) {
        flex: 0 0 157px;
      }

      .card-body {
        padding: 15px 10px;
        .price {
          color: $st-tropaz;
          font-family: $exo;
          font-size: 28px;
          font-weight: 600;
          line-height: 35px;
          margin-bottom: 0;

          @include media-breakpoint-up(lg) {
            font-size: 40px;
            line-height: 50px;
          }
        }
        .descriptor {
          color: $shakespeare;
          font-family: $roboto;
          font-size: 11px;
          font-weight: 700;
          line-height: 13px;

          @include media-breakpoint-up(lg) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
      .card-footer {
        padding: 10px;
        color: $white;
        font-family: $exo;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }

  .fine-print {
    margin-top: 30px;
    p {
      font-size: 11px;
      line-height: 13px;

      @include media-breakpoint-up(lg) {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}