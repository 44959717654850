.team-block {

  @include media-breakpoint-up(md) {

  }

  .team-container {
    @include media-breakpoint-up(lg) {
      padding-left: 10px;
      padding-right: 10px;
    }
    .row {
      @include media-breakpoint-up(lg) {
        margin-right: -10px;
        margin-left: -10px;
      }

      .col-lg-3 {
        margin-top: 20px;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
          margin-top: 30px;
          margin-bottom: 30px;
        }
        @include media-breakpoint-up(lg) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    [data-role="bio"] {
      display: none;
    }
  }
  .card {
    max-width: 315px;
    border: none;
    box-shadow: 0px 3px 20px rgba(124, 124, 124, .72);
    height: 100%;
    cursor: pointer;

    &:after {
      content:'';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -16px;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-top: 16px solid transparent;
      border-left: 17px solid transparent;
      border-right: 17px solid transparent;
    }

    .card-body {
      padding: 30px;

      .h5 {
        margin-bottom: 10px;
      }
      p {
        font-family: $exo;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;

        @include media-breakpoint-up(xlg) {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
    &.active {
      border: 3px solid $shakespeare;

      &:after {
        border-top-color: $shakespeare;
      }
    }
  }
}