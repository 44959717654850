.product-roadmap-block {
  table {
    width: 100%;

    tbody {
      tr {
        border-top: 1px solid $light-gray;

        td {
          padding: 20px 0;

          &.vote {
            padding-right: 20px;
            text-align: center;

            .upvote-vote {
              transition: color .2s linear;

              &.upvote-vote-match {
                color: $shakespeare;
              }
            }

            span.upvote-tally {
              color: $pale-sky;
              font-family: $roboto;
              font-weight: 600;
            }
          }

          &:nth-of-type(2) {
            div {
              &.status {
                color: $shakespeare;
                font-size: 12px;
                line-height: 14px;
                font-family: $roboto;
                font-weight: 600;
                text-transform: uppercase;
              }

              &.title {
                color: $st-tropaz;
                font-size: 18px;
                line-height: 21px;
                font-family: $roboto;
                font-weight: 600;
                margin: 10px 0;
              }

              &.description {
                color: $pale-sky;
                font-size: 16px;
                line-height: 26px;
                font-family: $roboto;
              }
            }
          }
        }
      }
    }
  }
}