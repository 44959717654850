.partners-block {
  .partner {
    margin: 20px 60px;
    padding: 10px 0;
    display: inline-flex;
    vertical-align: middle;

    img {
      width: 250px;
    }
  }
}