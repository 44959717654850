.offerings-block {
  .content {
    margin-top: 30px;
    margin-bottom: 30px;

    img {
      margin-bottom: 20px;
      width: auto;
      height: 60px;
    }

    p {
      font-size: 18px;
    }
  }
}