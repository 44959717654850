.blog-article-author-block {
  .card {
    border-radius: 5px;
    border: none;
    box-shadow: 0px 3px 20px rgba(124, 124, 124, .72);
    padding: 60px;

    .d-md-flex {
      margin-bottom: 30px;
    }

    img {
      max-width: 120px;
      border-radius: 50%;
    }
    .content {
      //flex: 0 1 calc(100% - 150px);
      //max-width: calc(100% - 150px);
      //margin-left: 30px;
      margin: 30px 0;

      @include media-breakpoint-up(md) {
        flex: 0 1 calc(100% - 150px);
        max-width: calc(100% - 150px);
        margin-left: 30px;
      }

      p {
        font-size: 14px;
        line-height: 16px;

        &.title {
          color: $shakespeare;
          font-weight: 700;
          margin-bottom: 10px;
        }
      }
      .h2 {
        color: $st-tropaz;
        margin-bottom: 5px;
      }
    }
  }
}