.btn-filter.btn-group {
  margin: 0 20px;

  .btn.dropdown-toggle {
    font-family: $exo;
    font-size: 18px;
    font-weight: 600;
    height: 40px;
    line-height: 35px;
    padding: 0 40px 0 20px;
    margin-top: 0;
    min-width: 141px;
    color: $shakespeare;
    background: $white;
    border-width: 1px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.29);

    &:hover, &:active:enabled, &:focus, &:focus:enabled, &:active:focus:enabled, &:active:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
      background: $white;
      border-color: $shakespeare;
    }

    &.chevron::before {
      content: url("/images/filter-chevron.svg");
      height: 100%;
      display: inline-block;
      width: 14px;
      vertical-align: top;
      transform: rotateX(0deg);
      position: absolute;
      right: 20px;
      left: auto;
      top: 0;
      transition: transform 0.25s linear;
    }
    &.chevron::after {
      display: none;
    }
    &[aria-expanded="true"]{
      &.chevron::before {
        transform: rotateX(180deg);
      }
    }
  }
  .dropdown-menu {
    padding: 10px 0;
    margin: 0;

    .dropdown-item  {
      padding: 0px 15px;
      color: $st-tropaz;
      text-decoration: none;
      background-color: $white;
      -webkit-appearance: unset;

      input[type="radio"], input[type="checkbox"] {
        margin-right: 3px;
      }

      &:hover {
        background-color: $whisper;
      }
    }
  }
}