.newsletter-signup-block {
  padding: 95px 0;
  position: relative;

  .newsletter {
    max-width: 550px;

    @include media-breakpoint-up(lg) {
      max-width: 648px;
    }
  }

  .content {
    position: relative;
    z-index: 1;
  }
}