.hardware-categories-block {
  .card {
    border-radius: 5px;
    border: 3px solid transparent;
    box-shadow: 0px 3px 20px rgba(124, 124, 124, .72);
    margin: 20px 0;
    padding: 25px 30px;
    text-decoration: none;
    transition: border-color .25s linear;

    &:hover {
      border-color: $shakespeare;
    }

    @include media-breakpoint-up(md) {
      margin: 30px 0;
      padding: 15px 30px;
    }

    .content {
      .h4 {
        @include media-breakpoint-up(xlg) {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }

    img {
      margin-right: 30px;
      max-height: 120px;
      width: auto;

      @include media-breakpoint-up(md) {
        margin-right: 35px;
        max-height: 140px;
      }

      @include media-breakpoint-up(xlg) {
        margin-right: 40px;
        max-height: 160px;
      }
    }
  }
}