.blog-articles-grid-block {
  .card {
    .subject {
      text-decoration: none;

      &:hover {
        p {
          color: $st-tropaz;
        }
      }
    }
  }
}