.bordered-cards-container {
  max-width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 750px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 1125px;
  }

  @include media-breakpoint-up(xlg) {
    max-width: 1365px;
  }
  .bordered-card {
    margin: 30px auto;

    &:focus {
      outline: none;
    }
    .card {
      height: 480px;
      width: 315px;
      margin: 0 auto;
      border-radius: 5px;
      border: none;
      box-shadow: 0px 3px 20px rgba(124, 124, 124, .72);
      padding: 42px 0;
      background: $white linear-gradient(90deg, #3C97D3 0%, #2A528E 100%) left bottom no-repeat padding-box;
      background-size: 100% 12px;

      @include media-breakpoint-up(xlg) {
        width: 375px;
      }

      .content {
        height: 396px;
        margin-left: 27px;
        margin-right: 27px;
        position: relative;

        @include media-breakpoint-up(xlg) {
          margin-left: 37px;
          margin-right: 37px;
        }

        .service-image {
          margin-bottom: 30px;
        }

        .subject-title {
          color: $shakespeare;
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
          margin-bottom: 20px;
        }

        p {
          font-size: 16px;
          line-height: 26px;

          @include media-breakpoint-up(xlg) {
            font-size: 18px;
            line-height: 28px;
          }
        }

        .btn.btn-primary {
          position: absolute;
          bottom: 0;
        }
      }
    }

    &.short {
      .card {
        height: 300px;

        .content {
          height: 216px;
        }
      }
    }
  }
}

