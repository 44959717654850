html {
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
}

body.lock-scroll {
  overflow: hidden;
}

.main-container {
  padding-top: 80px;

  @include media-breakpoint-up(lg) {
    padding-top: 155px;
  }
}

.standard-padding {
  padding: 80px 0;

  @include media-breakpoint-up(md) {
    padding: 120px 0;
  }
  @include media-breakpoint-up(xlg) {
    padding: 150px 0;
  }

  &-bottom {
    padding-bottom: 80px;
    @include media-breakpoint-up(md) {
      padding-bottom: 120px;
    }
    @include media-breakpoint-up(xlg) {
      padding-bottom: 150px;
    }
  }
  &-top {
    padding-top: 80px;
    @include media-breakpoint-up(md) {
      padding-top: 120px;
    }
    @include media-breakpoint-up(xlg) {
      padding-top: 150px;
    }
  }
}

.standard-margin {
  margin: 80px 0;

  @include media-breakpoint-up(md) {
    margin: 120px 0;
  }
  @include media-breakpoint-up(xlg) {
    margin: 150px 0;
  }

  &-bottom {
    margin-bottom: 80px;
    @include media-breakpoint-up(md) {
      margin-bottom: 120px;
    }
    @include media-breakpoint-up(xlg) {
      margin-bottom: 150px;
    }
  }
}

.half-m {
  margin: 40px 0;

  @include media-breakpoint-up(md) {
    margin: 60px 0;
  }

  &t {
    margin-top: 40px;

    @include media-breakpoint-up(md) {
      margin-top: 60px;
    }
  }
  &b {
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      margin-bottom: 60px;
    }
  }
}
//.px-30 {
//  padding-left: 30px;
//  padding-right: 30px;
//}


h1, h2, h3, h4, h5, h6, blockquote,
.h1, .h2, .h3, .h4, .h5, .h6, .h7, .h8 {
  color: $st-tropaz;
  font-family: $exo;
}

//hero title
.h1 {
  font-size: 40px;
  font-weight: 300;
  line-height: 50px;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    font-size: 50px;
    line-height: 60px;
  }
  @include media-breakpoint-up(xlg) {
    font-size: 60px;
    line-height: 80px;
  }
}
//section title
.h2 {
  color: $shakespeare;
  font-family: $roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    font-size: 24px;
  }
}
// block title - used on most blocks
.h3 {
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    font-size: 50px;
    line-height: 60px;
  }
}

// smaller title - on why noctel & feature callout block
.h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    font-size: 30px;
    line-height: 40px;
  }
}

//smaller title - on cards in services block and blog block
.h5 {
  font-family: $roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;

  @include media-breakpoint-up(xlg) {
    font-size: 21px;
    line-height: 30px;
  }
}

// smaller title - on 50/50 blocks
.h6 {
  color: $st-tropaz;
  font-family: $exo;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    font-size: 40px;
    line-height: 50px;
  }
}

//// title on feature request block and careers block
//.h7 {
//  font-size: 30px;
//  font-weight: 400;
//  line-height: 40px;
//  margin-bottom: 20px;
//
//  @include media-breakpoint-up(md) {
//    font-size: 40px;
//    line-height: 50px;
//  }
//
//  @include media-breakpoint-up(lg) {
//    font-size: 50px;
//    line-height: 60px;
//  }
//}

// smaller title - Why we care block on about page
// option title - Help options block
.h8 {
  font-size: 30px;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 0;

  @include media-breakpoint-up(lg) {
    font-size: 40px;
    line-height: 50px;
  }
}

// hero subtitle (above title) on left-aligned hero
.s1 {
  color: $shakespeare;
  font-family: $exo;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;
}

// case study block subtitle (above title)
.s2 {
  color: $shakespeare;
  font-family: $exo;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 10px;

  @include media-breakpoint-up(xlg) {
    font-size: 18px;
    line-height: 21px;
  }
}

// Hero subtitle paragraph text
// Subtitle on 50/50 case study & feature callout block
.s3 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    font-size: 30px;
    line-height: 40px;
  }
}

//check-list item
.s4 {
  color: $st-tropaz;
  font-family: $exo;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 20px;


  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}

// Subtitle feature request block
.s5 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;

  @include media-breakpoint-up(lg) {
    font-size: 30px;
    line-height: 40px;
  }
}

.mw {
  &-250 {
    max-width: 250px;
  }
  &-500 {
    max-width: 500px;
  }
  &-675 {
    max-width: 675px;
  }

  &-md {
    @include media-breakpoint-up(md) {
      &-none {
        max-width: none;
      }
    }
  }

  &-lg {
    @include media-breakpoint-up(lg) {
      &-none {
        max-width: none;
      }
      &-500 {
        max-width: 500px;
      }
      &-1140 {
        max-width: 1140px;
      }
    }
  }
  &-xlg {
    @include media-breakpoint-up(xlg) {
      &-none {
        max-width: none;
      }
      &-500 {
        max-width: 500px;
      }
      &-1140 {
        max-width: 1140px;
      }
    }
  }
}


p, address {
  color: $pale-sky;
  font-family: $roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    font-size: 18px;
    line-height: 28px;
  }

  a {
    font-size: inherit;
    line-height: inherit;
  }
}

.content {
  ul, ol {
    margin-bottom: 30px;
    margin-top: 30px;
    li {
      color: $pale-sky;
      font-family: $roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 20px;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  ul {
    list-style: none;
    padding-left: 20px;
    li::before {
      content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: $shakespeare;
      display: inline-block;
      margin-left: -15px;
      padding-right: 10px;
      font-size: 15px;
    }
    @include media-breakpoint-up(lg) {
      padding-left: 25px;

      li::before {
        margin-left: -25px;
        padding-right: 17px;
        font-size: 24px;
      }
    }
  }
}

.basic-content {
  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    color: $st-tropaz;
  }
  p {
    margin-top: 30px;
  }
}

a {
  color: $shakespeare;
  font-family: $roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-decoration: underline;
  transition: color 0.3s linear;

  @include media-breakpoint-up(lg) {
    font-size: 18px;
    line-height: 28px;
  }

  &:hover {
    color: $st-tropaz;
  }
}

.btn {
  font-family: $roboto;
  font-size: 16px;
  line-height: 40px;
  height: 45px;
  transition: background-color 0.25s linear, color 0.25s linear, box-shadow 0.25s linear, border .25s linear;
  text-align: center;
  border-radius: 5px;
  font-weight: 700;
  width: unset;
  min-width: 150px;
  padding: 0 20px;
  display: inline-block;
  box-shadow: 0px 3px 15px rgba(124, 124, 124, .72);
  outline: none;
  border-width: 3px;
  margin-top: 60px;
  text-decoration: none;
  pointer: cursor;

  @include media-breakpoint-up(md) {
    margin-top: 60px;
    min-width: 177px;
    height: 55px;
    font-size: 18px;
    line-height: 50px;
  }

  &:hover, &:active:enabled, &:focus, &:focus:enabled, &:active:focus:enabled, &:active:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0px 4px 14px rgba(124, 124, 124, .72);
    outline: none;
    background-color: transparent;
    text-decoration: none;
  }

  &.btn-primary {
    background-color: $shakespeare;
    border-color: $shakespeare;

    &:hover, &:active:enabled, &:focus, &:focus:enabled, &:active:focus:enabled, &:active:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0px 4px 14px rgba(124, 124, 124, .72);
      border-color: $st-tropaz;
      background-color: $st-tropaz;
    }
  }

  &.btn-secondary {
    background-color: transparent;
    color: $st-tropaz;
    border-color: $st-tropaz;

    &:hover, &:active:enabled, &:focus, &:focus:enabled, &:active:focus:enabled, &:active:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0px 4px 14px rgba(124, 124, 124, .72);
      color: $white;
      background-color: $st-tropaz;
    }
  }

  &.btn-download {
    box-shadow: none;
    font-size: 18px;
    line-height: 26px;
    min-width:0;
    width: 117px;
    padding: 20px 8px;
    background-color: $white;
    color: $st-tropaz;
    border-color: white;
    height: auto;

    img {
      margin-bottom: 4px;
    }

    &:hover, &:active:enabled, &:focus, &:focus:enabled, &:active:focus:enabled, &:active:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus {
      box-shadow: 0px 3px 6px rgba(124, 124, 124, .72);
      color: $st-tropaz;
      background-color: $white;
    }
  }
}

.multi-btn {
  margin-top: 40px;

  .btn {
    margin-top: 20px;
    &:not(:last-of-type) {
      margin-right: 10px;

      @include media-breakpoint-up(md) {
        margin-right: 20px;
      }
    }
  }
  &.centered-btn {
    .btn {
      margin-left: 5px;
      margin-right: 5px;

      @include media-breakpoint-up(md) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 87px;
}

.slick-dots {
  bottom: -87px;
  li {
    height: 22px;
    width: 22px;
    margin: 0 10px;

    button {
      background-color: $pattens-blue;
      border-radius: 50%;
      height: 22px;
      width: 22px;
      transition: background-color 0.3s linear;

      &:before {
        content: none;
      }
    }
    &.slick-active, &:hover {
      button {
        background-color: $st-tropaz;
      }
    }
  }
  > li:only-child {
    display: none;
  }
}

.bg-whisper {
  background-color: $whisper;
}

.bg-shakespeare {
  background-color: $shakespeare;
}

.bg-st-tropaz {
  background-color: $st-tropaz;
}

.bg-squares {
  background-size: auto 130px;
  background-repeat: repeat-x;
  height: 130px;

  &.bg-squares-light-blue {
    background-image: url('/images/NocTel-Squares_Light-Blue.svg');
  }

  &.bg-squares-light-grey {
    background-image: url('/images/NocTel-Squares_Light-Grey.svg');
  }

  &.bg-squares-dark-grey {
    background-image: url('/images/NocTel-Squares_Dark-Grey.svg');
  }

  &.bg-squares-top {
    background-position: top center;
  }
  &.bg-squares-bottom {
    background-position: bottom center;
    transform: rotate(180deg);
  }

  &.absolute {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}



.shadow-top {
  //background: transparent linear-gradient(0deg, #2A528E 0%, #1A3050 100%) 0% 0% no-repeat padding-box;
  background: transparent linear-gradient(0deg, rgba(42, 82, 142, 0), rgba(26, 48, 80, 1)) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 0.1;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}


.container-fluid {
  &.xlg-max-width {
    @include media-breakpoint-up(md) {
      max-width: 1600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.section-header {
  .h2 {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .s3 {
    margin-top: 30px;
    margin-bottom: 0;

    p {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 30px;

      @include media-breakpoint-up(md) {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
}

img {
  max-width: 100%;
}

// Scroll fix
:target::before {
  content: "";
  display: block;
  height: 80px; /* fixed header height*/
  margin: -80px 0 0; /* negative fixed header height */

  @include media-breakpoint-up(md) {
    height: 95px;
    margin: -95px 0 0;
  }
}

nav.page-filter {
  padding: 0;
  margin-bottom: 30px;

  &:after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    height: 5px;
    background: $ghost;
    bottom: 0;
    left: 0;
    border-radius: 4px;
    z-index: 1;
  }

  .indicator {
    height: 5px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: $shakespeare;
    border-radius: 4px;
    transition: all .2s ease-in-out;
    z-index: 2;
  }

  .navbar-toggler {
    .navbar-toggler-icon {
      background-color: $light-gray;
    }
  }

  .navbar-collapse {
    ul {
      margin: 0 auto;

       li {
         padding: 0 65px;
         text-align: center;
         display: flex;

         &.active {
           a {
             color: $st-tropaz;
           }
         }

         a {
           color: $ghost;
           text-decoration: none;
           transition: color .25s linear;
           font-family: $exo;
           font-size: 18px;
           font-weight: 600;
           line-height: 26px;
           margin-bottom: 15px;
           align-self: flex-end;
           cursor: pointer;
           //white-space: nowrap;

           @include media-breakpoint-up(md) {
             font-size: 26px;
           }

           &:hover, &:active {
             color: $st-tropaz;
           }
         }
       }
    }
  }
  [data-role="more-menu"] {
    .dropdown-toggl {
      position: relative;

      .navbar-toggler-icon {
        width: 5px;
        height: 19px;
        position: absolute;
        left: -5px;
        top: 5px;
      }
    }
    .dropdown-menu {
      border-color: $shakespeare;
      top: 90%;

      .nav-item {
        text-align: center;
        padding: 0 30px;

        a {
          width: 100%;
        }
      }
      //
      //&.show {
      //  border-color:
      //}
    }
  }
}