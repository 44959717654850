.noctel-difference-block {
  .audio-card {
    max-width: 450px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 3px 15px rgba(124, 124, 124, .72);
    padding: 30px 15px;

    @include media-breakpoint-up(md) {
      padding: 40px;
    }

    .nav-tabs  {
      border-bottom: none;

      .nav-item {
        margin-bottom: 0;

        .nav-link {
          border: none;
          border-bottom: 3px solid transparent;
          color: $ghost;
          font-family: $exo;
          font-size: 28px;
          font-weight: 600;
          padding: 0 0 10px;
          margin: 0 15px;
          text-decoration: none;

          @include media-breakpoint-up(md) {
            margin: 0 22px;
          }

          &.active {
            color: $st-tropaz;
            border-color: $st-tropaz;
            outline: none;
          }
          &:hover,
          &:focus {
            border-color: $st-tropaz;
            outline: none;
          }
        }
      }
    }
    .tab-content {
      padding-top: 40px;
    }

    audio {
      width: 100%;

      &:focus {
        outline: none;
      }

      &::-webkit-media-controls-panel {
        background-color: $ghost;
      }
    }
  }
}